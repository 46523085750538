body {
  font-family: Roboto, sans-serif;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-28 {
  font-size: 1.75rem;
}

.overflow-hidden {
  overflow: hidden;
}

.align-self {
  align-self: center;
}

.jumbotron {
  background: #fafafa;
  padding-top: 0;
  padding-bottom: 0;
}

.btn {
  font-size: 0.8rem;
  font-weight: 500;
}

.btn-primary {
  color: #fff;
  background-color: #3f6ad8;
  border-color: #3f6ad8;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 0.66rem;
  padding-right: 0.66rem;
  font-size: 0.88rem;
}

.dropdown-menu {
  text-transform: uppercase;
  font-size: 0.73333rem;
  color: #3f6ad8;
  font-weight: bold;
  border-radius: 10px;
  padding: 0.65rem;
}

.dropdown-menu .dropdown-item {
  font-size: 0.88rem;
  padding: 0.4rem 1.5rem;
  border-radius: 30px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e0f3ff;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -195px;
  }
}

@media (min-width: 992px) {
  #productsNavigation .dropdown-menu {
    left: 0;
  }
}

.card {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-width: 0;
  transition: all 0.2s;
}

.card-header {
  background-color: white;
  border-bottom: 1px solid rgba(26, 54, 126, 0.125);
}

.card-header,
.card-title {
  text-transform: uppercase;
  color: rgba(13, 27, 62, 0.7);
  font-weight: bold;
  font-size: 0.88rem;
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid rgba(26, 54, 126, 0.125);
}

.brands-home {
  background: #fafafa;
}

.categories--home {
  background: #f1f4f6;
  & h2 {
    color: rgba(13, 27, 62, 0.7);
  }
}

.header-shadow {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  background: #fafbfc;
  height: 70px;
}

.navbar-brand > img {
  width: 60%;
}

#searchfield {
  position: relative;
}

.productsSearchIcon {
  position: absolute;
  z-index: 2;
  right: 1rem;
}

.prodBorder {
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-radius: 0.25rem;
}

#productsNavigation {
  background: #febd17 !important;
  & .nav-link {
    color: black;
    font-weight: 500;
  }
}

#contact-cards .card {
  min-height: 160px;
  & p {
    margin-bottom: 0;
    margin-top: 2rem;
  }
}

#contact-cards > div:first-child {
  & a {
    margin-bottom: 0;
    margin-top: 2rem;
  }
}

.contact-card--1 {
  background: #c3002f;
  & a {
    color: white;
  }
}

.contact-card--4 {
  background: #34b7f1;
  & a {
    color: white;
  }
}

.contact-card--3 {
  background: #adcb00;
  & a {
    color: white;
  }
}

.contact-card--2 {
  background: goldenrod;
  & a {
    color: white;
  }
}

@media (max-width: 425px) {
  #header-navbar-nav {
    border-radius: 0 0 8px 8px;
    background: url(images/backgound-abstract.jpg) no-repeat;
    background-size: 100% 100%;
    filter: grayscale(80%);
    z-index: 3;
    width: 100%;
    padding: 1rem;
    & .navbar-nav > a,
    & .navbar-nav > div > a {
      color: white;
    }
    & .dropdown-item {
      white-space: normal;
    }
  }

  #productsNavigation #prod-navbar-nav {
    background: transparent;
    z-index: 2;
    width: 100%;
    padding: 1rem;
    & .navbar-nav > a,
    & .navbar-nav > div > a {
      color: #212529;
    }
    & .dropdown-item {
      white-space: normal;
    }
  }

  #productsNavigation button.navbar-toggler {
    border-color: transparent;
    & .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }

  .header-shadow {
    height: 80px;
  }
}

@media (max-width: 320px) {
  #headerNavigation .navbar-brand {
    width: 78%;
    margin-right: 0;
  }
}

footer {
  background-color: #232b40;
  color: rgba(255, 255, 255, 0.8);
  & a {
    color: inherit;
    &:hover {
      color: ivory;
    }
  }
}
